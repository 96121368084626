.dashboard {
  th {
    padding: 0px 0px !important;
  }
  td {
    padding: 8px 6px !important;
  }

  td.cell-kpi:hover {
    background-color: hsv(0, 0, 98%) !important;
  }

  thead {
    tr:first-child {
      th {
        border-bottom: 0;
      }
    }

    tr:last-child {
      vertical-align: top;
    }
  }

  // tableに対してpaddingをかけたかったがいい感じにならなかったので、下記のように設定
  tbody {
    tr:last-child {
      height: 94px;

      td {
        padding-bottom: 12px !important;
      }
    }
  }

  // headerとbodyでカラムの位置を揃えるためにantdが強制的にいれている行
  .ant-table-measure-row {
    td {
      padding: 0px !important;
    }
  }

  .ant-table-column-sorters {
    width: 100%;
    justify-content: center; // 中央揃えするため
  }

  .ant-table-column-sorter {
    margin-left: 0px;
    position: absolute;
    bottom: 3%;
    right: 5%;
    opacity: 0.4;
  }

  // .ant-table-column-sort {
  //   .ant-table-column-sorter {
  //     opacity: 1;
  //   }
  // }

  .ant-table-filter-trigger {
    // 何の影響か分からないがズレてるため。
    margin: 0;
    margin-right: 2px;
  }

  .ant-table-filter-trigger .anticon {
    top: inherit;
    bottom: 1%;
    opacity: 0.4;
  }

  .ant-table-filter-trigger.active {
    opacity: 1;
  }
}

.records-table {
  td {
    padding: 0.7em 0.4em !important;
  }
  .ant-table-column-sorters {
    padding: 16px 0px !important;
    padding-left: 4px !important;
  }
}

.dashboard-total {
  td {
    padding-bottom: 6px !important;
  }
}

// 表示が崩れていたので
.ant-steps-small .ant-steps-item-icon {
  line-height: 22px;
}

// 必須
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '必須';
  font-size: 6pt;
  background-color: #f87171;
  color: white;
  padding: 2px 4px;
  border-radius: 2px;
}

.property-cascader {
  .ant-cascader-menu {
    width: 150px;
    height: 300px;
  }

  .ant-cascader-menu:first-child {
    width: 450px;
  }

  .ant-cascader-menu:nth-child(3) {
    width: 350px;
  }
}

.user-groups-tree {
  .ant-tree-switcher-line-icon {
    padding-top: 20px !important;
  }

  .ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line::before {
    height: 28px !important;
  }

  .ant-tree-switcher-leaf-line::after {
    height: 28px !important;
  }
}
