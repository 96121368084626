.uploadcare--widget__button {
  background: #376df8;
  cursor: pointer;
}

.uploadcare--dialog__container {
  font-family: '游ゴシック体,Yu Gothic,YuGothic,ヒラギノ角ゴ Pro W3,Hiragino Kaku Gothic Pro,メイリオ,Meiryo,ＭＳ Ｐゴシック,Osaka,sans-serif' !important;
}

.uploadcare--text_size_extra-large {
  font-size: 1.8em;
  font-weight: bold;
}

.uploadcare--button_size_big {
  font-size: 1.2em;
  line-height: 1.3em;
  cursor: pointer;
}

.uploadcare--powered-by {
  display: none;
}
