html {
  touch-action: manipulation;
}

html,
body,
#__next {
  height: 100%;
}

pre {
  white-space: pre-wrap !important; // エラー画面のために使用。他で問題があれば、影響範囲の調査の後改修
  font-family: inherit !important;
}

body {
  // background-color: #1b202b;
  background-color: #fafafa;
}

.overflow-scroll-layout-content {
  height: 100%;
  overflow-y: scroll;
}

@media print {
  .dispaly-none-print {
    display: none !important;
  }

  .overflow-visible-print {
    overflow: visible !important;
  }
}

.sider-hover-light {
  transition: background-color 0.2s;
}
.sider-hover-light:hover {
  background-color: #203549;
}
