/* To use tailwindcss with ant design */
/* @tailwind base; */

@tailwind components;
@tailwind utilities;

.bg-blue-50 {
  background-color: #eff6ff;
}

.bg-blue-20 {
  background-color: #f8f9ff;
}

.bg-gray-50 {
  background-color: #fdfeff; // background-color-lightと揃える
}

.text-xxs {
  font-size: 5px;
}
